<template>
  <div class="d-flex justify-content-center">
    <b-row class="col-12 mt-2">
      <b-col class="col-12 text-center">
        <b-button variant="outline-secondary" class="mb-2" @click="addLink">
          <feather-icon icon="PlusIcon"></feather-icon>
          {{ $t('creator.addLink') }}
        </b-button>
        <b-col v-for="(link, index) in links" :key="index" class="col-12 p-0 pb-1 mb-1 border-bottom d-flex flex-wrap justify-content-between box-previews-draggable">
          <div :class="`${!urlStateExpression(link.link) ? 'border-link-danger' : 'border-link-success'} d-flex align-items- w-100`">
            <b-form-group class="w-100">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="LinkIcon"
                    :class="!urlStateExpression(link.link) ? 'text-invalid' : 'text-success'"
                  />
                </b-input-group-prepend>
                <b-form-input v-model="link.link" class="input-link h-100" autocomplete="off"></b-form-input>
              </b-input-group>
              <small v-if="!urlStateExpression(link.link)" class="text-invalid">{{ $t('creator.enterUrl') }}</small>
            </b-form-group>
            <b-button variant="flat-secondary" class="flat-secondary-delete-links" @click="$emit('delete_link', link)">
              <feather-icon icon="TrashIcon"></feather-icon>
            </b-button>
          </div>

          <b-dropdown class="w-100" menu-class="w-100" variant="flat-secondary">
            <template #button-content>
              {{(link.type_link) ? $t('creator.asset_type').find((t) => t.value === link.type_link).text : $t('creator.assetType')}}
            </template>
            <b-dropdown-item v-for="(item) in $t('creator.asset_type')" :key="item.value" @click="addAssetType(link, item.value)">
              <div class="d-flex align-items-center">
                <b-icon :icon="item.icon" class="mr-05"></b-icon>
                <div>
                  <strong>{{item.text}}</strong>
                  <p class="avenir-medium m-0 text-description-url">{{item.description}}</p>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import { getUniqueIndex } from '@/libs/utils/others'
import { urlStateExpression } from '@/libs/utils/urls'

export default {
  name: 'stepAttachmentLinks',
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      urlStateExpression,
      getUniqueIndex,
    }
  },
  props: {
    links: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    addLink() {
      this.links.push({link: 'https://', asset_type: null, id: getUniqueIndex()})
    },
    addAssetType(link, type_link) {
      link.type_link = type_link;
      this.$emit('update_button_save')
    },
    // getData() {
    //   if (this.has_slug) {
    //     // this.casting_call = Object.assign({}, this.$route.params.casting_call_data)
    //     // this.casting_call_uuid = this.casting_call.uuid;
    //     // if (this.casting_call.links.length > 0) this.links = this.casting_call.links;
    //   } else {
    //     this.steps = JSON.parse(localStorage.getItem('steps'));
    //     this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
    //     casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
    //       this.casting_call = response;
    //       if (this.casting_call.links.length > 0) this.links = this.casting_call.links;
    //     });
    //   }
    // }
  },
}
</script>
<style scoped>
.text-description-url {
  white-space:break-spaces
}
.flat-secondary-delete-links {
  padding: 0.5em !important;
  height: 35px !important;
  margin-left: 1em;
}
.spinner-waiting-service {
  width: 18px;
  height: 18px;
}
.spinner-ws-photos-api {
  height: 25px;
  width: 25px;
  color: #ccc;
}
.mr-05 {
  margin-right: 0.5em;
}
.text-invalid {
  color: #496daa;
}
.border-link-danger .input-group-prepend .input-group-text {
  border-right: 0;
  border-color: #496daa;
}
.border-link-success .input-group-prepend .input-group-text {
  border-right: 0;
  border-color: #28C76F;
}
</style>